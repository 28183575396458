








































import { Component, Vue } from 'vue-property-decorator';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
} from '@/interfaces';
import { appName } from '@/env';
import { dispatchGetUsers, dispatchCreateUserPublic } from '@/store/admin/actions';

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public fullName: string = '';
  public phoneNumber: string = '';
  public email: string = '';
  public isActive: boolean = false;
  public isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';
  public appName = appName;

  public async mounted() {
    // await dispatchGetUsers(this.$store);
    this.reset();
  }

  public reset() {
    this.password1 = '';
    this.password2 = '';
    this.fullName = '';
    this.phoneNumber = '';
    this.email = '';
    this.isActive = false;
    this.isSuperuser = false;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const newProfile: IUserProfileCreate = {
        email: this.email,
      };
      if (this.fullName) {
        newProfile.full_name = this.fullName;
      }
      if (this.email) {
        newProfile.email = this.email;
      }
      if (this.phoneNumber) {
        newProfile.phone = this.email;
      }
      newProfile.is_active = this.isActive;
      newProfile.is_superuser = this.isSuperuser;
      newProfile.password = this.password1;
      await dispatchCreateUserPublic(this.$store, newProfile);
      this.$router.push('/main/admin/users');
    }
  }
}
